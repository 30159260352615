<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="手动添加" name="first" v-loading="page_form_loading">
          <div class="add-wrapper">
            <div class="add-box">
              <div class="box-line">
                <div class="column-name">姓名 <span style="color: red">*</span></div>
                <div class="column-gender">性别<span style="color: red">*</span></div>
                <div class="column-phone">手机号<span style="color: red">*</span></div>
                <div class="column-org">归属组织<span style="color: red">*</span></div>
                <div class="column-role">岗位角色</div>
                <div class="column-usertype">用户类型<span style="color: red">*</span></div>
                <div class="column-password">登录密码<span style="color: red">*</span></div>
                <div class="column-super">是否电子签超管</div>
                <div class="column-idNo">身份证号</div>
                <div class="column-opr">操作</div>
              </div>
              <div class="box-line" v-for="(user,index) in users" :key="index">
                <div class="column-name">
                  <el-input v-model="users[index].name" placeholder="姓名"></el-input>
                </div>
                <div class="column-gender">
                  <el-select placeholder="性别" v-model="users[index].gender">
                    <el-option label="男" :value="0"></el-option>
                    <el-option label="女" :value="1"></el-option>
                  </el-select>
                </div>
                <div class="column-phone">
                  <el-input v-model="users[index].phone" placeholder="手机号" type="number"></el-input>
                </div>
                <div class="column-org">
                  <el-cascader :ref="'organizationCascaderRef'+index" v-model="users[index].tmp_organization_id"
                               :options="organizationalData" :show-all-levels="false"
                               style="width: 320px"
                               @change="organizationChangeV2('organizationCascaderRef'+index,index)"
                               :props="{ checkStrictly: true, label: 'name', value: 'id' ,children: 'distributorList' }">
                  </el-cascader>
                </div>
                <div class="column-role">
                  <el-select v-model="users[index].roleIds" placeholder="岗位角色">
                    <el-option :label="role.name" :value='role.id' v-for="(role,roleIndex) in role_options"
                               :key="roleIndex"/>
                  </el-select>
                </div>
                <div class="column-usertype">
                  <el-select disabled v-model="users[index].userType" placeholder="用户类型">
                    <el-option label="集团用户" value='admin'/>
                    <el-option label="企业用户" value='enterprise'/>
                    <el-option label="经销商" value='distributor'/>
                  </el-select>
                </div>
                <div class="column-password">
                  <el-input v-model="users[index].password" placeholder="登录密码"></el-input>
                </div>
                <div class="column-super">
                  <el-checkbox v-model="users[index].isTencentSignSuperAdmin"
                               :disabled="users[index].userType === 'distributor'"></el-checkbox>
                </div>
                <div class="column-idNo">
                  <el-input v-model="users[index].idNo" placeholder="身份证号码"></el-input>
                </div>
                <div class="column-opr">
                  <el-button type="text" @click="deleteUserItem(index)">删除</el-button>
                </div>
              </div>
              <div class="add-line">
                <el-button type="text" icon="el-icon-plus" @click="addUserItem(index)"> 新增员工</el-button>
              </div>
              <div class="box-footer">
                <el-button type="primary" size="small" @click="submitMulti">保存</el-button>
                <el-button type="info" size="small" @click="cancelAction">取消</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入" name="second">
          <div
              style="width: 1000px;margin: 0 auto;padding: 30px;display:flex;flex-direction: column;align-items:center;background-color: #fff;border-radius: 10px;"
              v-loading="loading">
            <div style="font-size: 12px;padding: 10px 0;width: 360px;text-align: left">
              导入前，请先下载 <span @click="downloadTemplate" style="color:#409eff">架构导入模板</span>，进行填写后上传
            </div>
            <el-upload ref="excelUpload" class="upload-demo" drag :http-request="handleFileUpload" action="-">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过5M</div>
            </el-upload>
            <div style="margin-top: 20px;width: 360px;text-align: left">
              <el-button type="primary" @click="upload" size="small">导入</el-button>
              <el-button type="info" @click="cancelUpload" size="small">取消</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import downloadBlob from "@/function/downloadBlob";
import {
  downloadUserTemplate,
  uploadUserTemplate,
  userCreateMulti,
  roleAll
} from "@/api/common"
import {getDistributor} from "@/api/apis";
// import {COMPANY_NAME} from "@/utils/config";

export default {
  name: "AddStaff",
  data() {
    return {
      activeName: 'first',
      loading: false,
      uploadFileForm: {
        file: ''
      },
      page_form_loading: false,
      users: [{
        name: '',
        gender: '',
        phone: '',
        tmp_organization_id: [],
        developersId: '',
        distributorId: '',
        userType: null,
        password: '',
        isTencentSignSuperAdmin: false,
        idNo: null,
        roleIds: '',
      }],
      organizationTree: null,
      parentDistributorId: '',
      parentDevelopersId: '',
      role_options: [],
      // 组织管理
      organizationalData: [],
      isAdmin: false,
      isDevelopers: false,
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.parentDevelopersId = this.$route.query.developersId;
    this.parentDistributorId = this.$route.query.distributorId;
    this.getAllRoles()
    this.getDistributorV2()
  },
  mounted() {
  },
  methods: {
    /**
     * 组织管理
     */
    getDistributorV2() {
      this.organizationalData = []
      getDistributor().then(res => {
        const {isAdmin, isDevelopers} = res
        this.isAdmin = isAdmin
        this.isDevelopers = isDevelopers
        res.data.map(iter => {
          iter.node_id = `DEVELOPER_${iter.id}`
          iter.nodeType = 'DEVELOPER'
          if (iter.distributorList && iter.distributorList.length > 0) {
            iter.distributorList.map(item => {
              item.node_id = `DEALER_${item.id}`
              item.nodeType = 'DEALER'
            })
          }
        })
        this.organizationalData = res.data

        /* //
         const { isAdmin, isDevelopers } = res
         this.isAdmin = isAdmin
         this.isDevelopers = isDevelopers

         // -
         const data = {
           id: -1,
           label: COMPANY_NAME,
           children: [],
           nodeType: 'ROOT'
         }
         // ROOT DEALER-经销商 DEVELOPER-开发商
         // 经销商
         const developerData = res.data
         developerData.map(iter => {
           iter.children = iter.distributorList || []
           if (iter.distributorList) {
             iter.distributorList.map(item => {
               item.label = item.name + `(经销商)`
               item.nodeType = 'DEALER'
             })
           }
         })

         // 开发商
         res.data.forEach(iter => {
           if (iter.distributorList) {
             data.children.push({
               ...iter,
               label: iter.name + `(开发商)`,
               nodeType: 'DEVELOPER'
             })
           } else {
             data.children.push({
               ...iter,
               label: iter.name + `(经销商)`,
               nodeType: 'DEALER'
             })
           }
         })
         this.organizationalData.push(data)*/
      })
    },
    getDistributorInfo() {
      for (let organizationTreeKey of this.organizationTree[0].children) {
        for (let distributorListKey of organizationTreeKey.children) {
          if (distributorListKey.id === "distributor_" + this.parentDistributorId) {
            this.parentDevelopersId = organizationTreeKey.id.split("_")[1];
            return;
          }
        }
      }
    },
    getAllRoles() {
      let that = this
      roleAll().then(res => {
        that.role_options = res
      })
    },
    setOrganizationTreeSelect(index) {
      let tmp_organization_id = ['admin']
      //设置默认选中的数据
      if (this.parentDistributorId !== '') {
        tmp_organization_id.push('developer_' + this.parentDevelopersId)
        tmp_organization_id.push('distributor_' + this.parentDistributorId)
        this.users[index].userType = "distributor";
        this.users[index].distributorId = this.parentDistributorId;
        this.users[index].developersId = this.parentDevelopersId;
      } else if (this.parentDevelopersId !== '') {
        tmp_organization_id.push('developer_' + this.parentDevelopersId);
        this.users[index].userType = "enterprise";
        this.users[index].developersId = this.parentDevelopersId;
      }
      this.users[index].tmp_organization_id = tmp_organization_id;
    },
    /**
     *
     */
    organizationChangeV2(ref, index) {
      const nodes = this.$refs[ref][0].getCheckedNodes()
      if (nodes.length < 1) {
        return
      }
      const current = nodes[nodes.length - 1]
      // ROOT DEALER-经销商 DEVELOPER-开发商
      switch (current.data.nodeType) {
        case 'ROOT':
          this.users[index].userType = 'admin'
          this.users[index].developersId = ''
          this.users[index].distributorId = ''
          break
          // 经销商
        case 'DEALER':
          this.users[index].userType = 'distributor'
          this.users[index].developersId = current.data.developersId
          this.users[index].distributorId = current.data.id
          this.users[index].isTencentSignSuperAdmin = false
          break
          // 开发商
        case 'DEVELOPER':
          this.users[index].userType = 'enterprise'
          this.users[index].developersId = current.data.id
          this.users[index].distributorId = ''
          break
      }
    },
    organizationChange(ref, index) {
      let that = this
      let nodes = that.$refs[ref][0].getCheckedNodes()
      if (nodes.length < 1) return
      let current = nodes[nodes.length - 1]
      switch (current.data.type) {
        case 'admin':
          that.users[index].userType = 'admin'
          that.users[index].developersId = ''
          that.users[index].distributorId = ''
          break
        case 'developer':
          that.users[index].userType = 'enterprise'
          that.users[index].developersId = current.data.origin_id
          that.users[index].distributorId = ''
          break
        case 'distributor':
          that.users[index].userType = 'distributor'
          that.users[index].developersId = current.data.parent_id
          that.users[index].distributorId = current.data.origin_id
          that.users[index].isTencentSignSuperAdmin = false
          break
      }
    },
    deleteUserItem(index) {
      if (this.users.length > 1) {
        this.users.splice(index, 1)
      } else {
        this.$message.error('不能再删除了')
      }
    },
    addUserItem() {
      this.users.push({
        name: '',
        gender: '',
        phone: '',
        tmp_organization_id: [],
        developersId: '',
        distributorId: '',
        userType: null,
        password: '',
        idNo: null,
        roleIds: '',
      })
      this.setOrganizationTreeSelect(this.users.length - 1);
    },
    handleTabClick(tab) {
      if (tab.name == 'first') {
        this.users = [{
          name: '',
          gender: '',
          phone: '',
          tmp_organization_id: [],
          developersId: '',
          distributorId: '',
          userType: null,
          password: '',
          isTencentSignSuperAdmin: false,
          idNo: null,
          roleIds: '',
        }]
      }
      if (tab.name == 'second') {
        this.$refs['excelUpload'].clearFiles()
        this.uploadFileForm = {
          file: ''
        }
      }
    },
    handleFileUpload(source) {
      let that = this
      that.uploadFileForm.file = source.file
    },
    downloadTemplate() {
      let that = this
      downloadUserTemplate(that.searchModel).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    },
    upload() {
      let that = this
      let formData = new FormData()
      formData.append('file', that.uploadFileForm.file);
      that.loading = true
      uploadUserTemplate(formData).then(() => {
        that.$message.success('批量导入成功')
        that.loading = false
        that.$router.push({path: '/admin/organization'})
      }).catch(() => {
        that.loading = false
      })
    },
    cancelUpload() {
      this.$router.push({path: '/admin/organization'})
    },
    submitMulti() {
      console.log('-----------', this.users)

      let that = this
      that.page_form_loading = true
      userCreateMulti(that.users).then(() => {
        that.page_form_loading = false
        that.$message.success('操作成功')
        that.$router.push({path: '/admin/organization'})
      }).catch(() => {
        that.page_form_loading = false
      })
    },
    cancelAction() {
      this.$router.push({path: '/admin/organization'})
    },
  },
}
</script>

<style lang="scss" scoped>

.add-wrapper {
  min-width: 1360px;
  width: 100%;
  padding: 30px;
  background-color: #FFFFFF;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 5px;

  .add-box {
    width: 100%;
    min-width: 1300px;
    border: 1px solid #ccc;


    .box-line {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      font-size: 14px;

      & > div {
        padding: 10px;
        text-align: center;
      }

      .column-gender {
        width: 120px;
      }

      .column-phone {
        width: 180px;
      }

      .column-name {
        width: 130px;
      }

      .column-org {
        width: 330px;
      }

      .column-role {
        width: 200px;
      }

      .column-usertype {
        width: 140px;
      }

      .column-password {
        width: 170px;
      }

      .column-super {
        width: 140px;
      }

      .column-idNo {
        width: 200px;
      }

      .column-opr {
        width: 130px;
      }
    }

    .add-line {
      color: #409eff;
      padding-left: 10px;
    }

    .box-footer {
      padding: 10px
    }
  }
}


</style>
